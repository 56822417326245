import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import UniversityLogos from '../../icons/university_logos.png';
import PeopleIcon from '../../icons/people_icon.png';
import SimutopiaImage from '../../icons/simutopia_image.png';
import SimutopiaIcon from '../../icons/simutopia_icon.png';
import ContactIcon from '../../icons/contact_icon.png';

const Description = ({ description }) => {
    // Check if description contains newlines
    const hasNewlines = description.includes('\n');
    
    // Split the description into an array if it has newlines
    const items = hasNewlines ? description.split('\n') : [];
  
    return (
      <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
        {hasNewlines ? (
          <ul style={{ paddingLeft: 20 }}>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p>{description}</p>
        )}
      </Typography>
    );
  };

const LearnMore = ({url}) => {
    if(url){
        return (
            <Link
                href= {url}
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                display: 'inline-flex',
                alignItems: 'center',
                '& > svg': { transition: '0.2s' },
                '&:hover > svg': { transform: 'translateX(2px)' },
                }}
                onClick={(event) => {
                event.stopPropagation();
                }}
            >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                fontSize="small"
                sx={{ mt: '1px', ml: '2px' }}
                />
            </Link>
        );
    } else{
        return (
            null
        );
    }
};
  

const items = [
  {
    icon: PeopleIcon,
    title: 'Who we are',
    description:
      'We are a startup by the alumni of IIT Madras and BITS Pilani. We are passionate about AR/VR technology and are focussed on building great tools and services around the use of AR and VR in various application areas like engineering, interiors, entertainment, education and tourism.',
    imageLight: `url(${UniversityLogos})`,
    imageDark: `url(${UniversityLogos})`,
  },
  {
    icon: SimutopiaIcon,
    title: 'What else we do',
    description:
      'We also advance design and innovation through simulation and analysis of engineering systems through our other startup SIMUTOPIA',
    imageLight: `url(${SimutopiaImage})`,
    imageDark: `url(${SimutopiaImage})`,
    url: 'https://www.simutopia.com'
  },
  {
    icon: ContactIcon,
    title: 'How to reach us',
    description:
      'E-mail - consulting@multivrselab.com \n USA - (+1) 774-207-8005 \n India - (+91) 897-764-1377',
    imageLight: `url(${SimutopiaImage})`,
    imageDark: `url(${SimutopiaImage})`,
  },
];

export default function About() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    if(items[selectedItemIndex].url)
        window.location.href = items[selectedItemIndex].url;
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="aboutus" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary" fontFamily={'Audiowide'}>
              About Us
            </Typography>
          </div>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Description description={selectedFeature.description} />
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description, url }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    <img
                      src={icon}
                      alt="logo of product"
                      width={'40px'}
                      height={'40px'}
                    />
                  </Box>
                  <div>
                    <Typography
                      color="primary.main"
                      variant="body2"
                      fontWeight="lighter"
                      fontSize={"20px"}
                      fontFamily={'Audiowide'}
                    >
                      {title}
                    </Typography>
                    <Description description={description} />
                    <LearnMore url={url}/>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
